import { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import GiftForm from './GiftForm';

const ConfigTitle: FC<any> = ({ record }) =>
  record ? <span>Gift #{record.id}</span> : null;

const GiftEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<ConfigTitle />} {...props}>
      <GiftForm />
    </Edit>
  );
};

export default GiftEdit;
