import GiftIcon from '@material-ui/icons/GifTwoTone';
import GiftList from './GiftList';
import GiftCreate from './GiftCreate';
import GiftEdit from './GiftEdit';

export default {
  list: GiftList,
  create: GiftCreate,
  edit: GiftEdit,
  icon: GiftIcon,
};
