import { httpClient } from 'httpClient';
import { siteIdChoices } from 'consts';

export enum Env {
  Production = 'production',
  Development = 'development',
  Stage = 'stage',
  StageExternal = 'stage-external',
}

const BASE_DEV_DOMAIN = 'spiddy.co';
const BASE_EXTERNAL_HOST = 'admin.externalscope.co';
const BASE_EXTERNAL_DOMAIN = 'externalscope.co';
const BASE_PROD_DOMAIN = 'pidval.co';

const getDevHostnameParts = (hostname: string) => {
  const reg = new RegExp(
    `(stage|dev|pid-\\d+)-admin\\.(${BASE_DEV_DOMAIN})|(${BASE_EXTERNAL_HOST})`,
  );
  const parts = hostname.match(reg);

  if (parts != null && parts[0] === BASE_EXTERNAL_HOST) {
    return {
      env: 'stage-external',
      domain: BASE_EXTERNAL_DOMAIN,
    };
  }

  return {
    env: parts ? parts[1] : Env.Production,
    domain: parts ? parts[2] : BASE_PROD_DOMAIN,
  };
};

export const getEnvByHostname = (hostname: string) => {
  const { env: envPart } = getDevHostnameParts(hostname);

  if (envPart === 'dev' || process.env.NODE_ENV === Env.Development) {
    return Env.Development;
  }

  if (envPart === Env.StageExternal) {
    return Env.StageExternal;
  }
  if (envPart === 'stage') {
    return Env.Stage;
  }

  if (envPart?.includes('pid')) {
    return Env.Stage;
  }

  return Env.Production;
};

export const getApiUrl = (hostname: string, env: Env): string => {
  if (env === Env.StageExternal) {
    return `https://admin-api.${BASE_EXTERNAL_DOMAIN}`;
  }

  if (env === Env.Production) {
    return `https://api.${BASE_PROD_DOMAIN}`;
  }

  const { env: envPart, domain: domainPart } = getDevHostnameParts(hostname);

  return `https://${envPart}-admin-api.${domainPart}`;
};

export const getWsUrl = (hostname: string, env: Env): string => {
  if (env === Env.Production) {
    return `wss://ws.${BASE_PROD_DOMAIN}`;
  }

  const { env: envPart, domain: domainPart } = getDevHostnameParts(hostname);

  return `wss://${envPart}-ws.${domainPart}`;
};

export const getEnv = (): Env => {
  const currentHostname = window.location.hostname;
  return getEnvByHostname(currentHostname);
};

export const isInternalUrl = (url: string): boolean => {
  const env = getEnv();
  const domain = new URL(url).hostname;
  const isInternal = siteIdChoices.map((t) => t.name).includes(domain);

  if (env === Env.Production || isInternal) {
    return isInternal;
  }
  const pattern = new RegExp(`^s\\d+\\.${BASE_DEV_DOMAIN}$`);

  return pattern.test(domain);
};

export const isValidUrl = (url: string): boolean => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-{}]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator

  return pattern.test(url);
};

export const getArrayFromLinkedList = async (link: string): Promise<any[]> => {
  const array: any = [];

  const response = await httpClient.get<any>(link);
  array.push(...response.data.data);

  if (response.data.links.next) {
    const lastArray = await getArrayFromLinkedList(response.data.links.next);

    return [...array, ...lastArray];
  } else {
    return array;
  }
};
