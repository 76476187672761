import { FC, memo, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import { ImageListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { MediaType } from 'types/mediaType';
import { TrustedUserMedia } from 'types/trustedUser/trustedUserModeration';

import MediaModerationBtn from './MediaModerationBtn';
import CustomPagination from './CustomPagination';
import { Env, getEnv } from 'utils';
import SuccessButton from 'layout/buttons/SuccessButton';
import ErrorButton from 'layout/buttons/ErrorButton';

const PHOTOS_ON_PAGE = 30;

interface Props {
  tabNumber: number;
  media: TrustedUserMedia[];
  setMedia: (
    newValue:
      | TrustedUserMedia[]
      | ((prev: TrustedUserMedia[]) => TrustedUserMedia[]),
  ) => void;
}

const useStyles = makeStyles({
  photosList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'auto 1fr',
    gap: '10px',
    padding: '10px 0',
  },
});

const MediaList: FC<Props> = memo(({ tabNumber, media, setMedia }) => {
  if (!media) {
    return null;
  }

  const currentEnv = getEnv();

  const { pathname } = useLocation();

  const ref = useRef<null | HTMLDivElement>(null);

  const [activePage, setActivePage] = useState(1);

  const renderedPhotos = useMemo(() => {
    return media.slice(
      PHOTOS_ON_PAGE * activePage - PHOTOS_ON_PAGE,
      PHOTOS_ON_PAGE * activePage,
    );
  }, [media, activePage]);

  const pages = useMemo(() => {
    return Array.from(
      { length: Math.round(media?.length / PHOTOS_ON_PAGE) || 1 },
      (_, index) => index + 1,
    );
  }, [media.length]);

  const classes = useStyles();

  const handleModerateMedia = ({
    id,
    statusId,
    isErotic,
  }: {
    id: number;
    statusId?: number;
    isErotic?: boolean;
  }) => {
    setMedia((prev: TrustedUserMedia[]) =>
      prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            is_erotic: isErotic !== undefined ? isErotic : item.is_erotic,
            status_id: statusId !== undefined ? statusId : item.status_id,
          };
        }
        return item;
      }),
    );
  };

  const handleApproveAllBtn = () => {
    setMedia(
      media.map((item) => ({
        ...item,
        status_id: 1,
      })),
    );
  };

  const handleDeclineAllBtn = () => {
    setMedia(
      media.map((item) => ({
        ...item,
        status_id: 3,
      })),
    );
  };

  const handleScroll = () => {
    const element = ref?.current;

    if (element) {
      setTimeout(
        () => element.scrollIntoView({ behavior: 'smooth', block: 'start' }),
        400,
      );
    }
  };

  const handlePageChange = (nextTargetPage: number) => {
    setActivePage(Number(nextTargetPage));
    handleScroll();
  };

  if (!pathname.endsWith(`/${tabNumber}`)) {
    return null;
  }

  if (media.length === 0) {
    return <p>No media for moderation</p>;
  }

  return (
    <div ref={ref}>
      {currentEnv !== Env.Production && (
        <>
          <SuccessButton
            style={{ marginBottom: '5px' }}
            onClick={handleApproveAllBtn}
          >
            Approve all
          </SuccessButton>
          <ErrorButton
            style={{ marginBottom: '5px' }}
            onClick={handleDeclineAllBtn}
          >
            Decline all
          </ErrorButton>
        </>
      )}
      <div>
        <div className={classes.photosList}>
          {renderedPhotos?.map((item) => {
            return (
              <ImageListItem
                key={item.id}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginRight: '5px',
                }}
              >
                {item.type === MediaType.Video ||
                item.type === MediaType.PrivateLibraryVideo ? (
                  <video
                    src={item.file_url}
                    style={{ width: '300px', maxHeight: '80%' }}
                    controls
                  />
                ) : (
                  <Zoom>
                    <img
                      src={item.file_url}
                      style={{
                        height: '300px',
                        maxWidth: '100%',
                        minWidth: '150px',
                        objectFit: 'contain',
                      }}
                      loading="lazy"
                    />
                  </Zoom>
                )}
                <MediaModerationBtn
                  item={item}
                  handleModerateMedia={handleModerateMedia}
                  isEroticCheckboxActive={
                    item.type === MediaType.PrivateLibraryPhoto ||
                    item.type === MediaType.PrivateLibraryVideo
                  }
                />
              </ImageListItem>
            );
          })}
        </div>

        <CustomPagination
          pages={pages}
          activePage={activePage}
          onChangeActivePage={handlePageChange}
        />
      </div>
    </div>
  );
});

export default MediaList;
