import { FC, useState } from 'react';
import {
  SimpleForm,
  required,
  Toolbar,
  SaveButton,
  NumberInput,
  SelectInput,
  minValue,
  maxValue,
  DateTimeInput,
  ImageInput,
  ImageField,
  DeleteButton,
  useSaveContext,
} from 'react-admin';
import { GiftType } from './field-choices';

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
  });

const GiftForm: FC<any> = ({ record, ...rest }) => {
  const { save } = useSaveContext();

  const handleSubmit = async (data) => {
    if (data.image) {
      const base64Image = await convertFileToBase64(data.image);
      const finalData = {
        ...data,
        image_blob: base64Image, // Add Base64 image to the data
        image_name: data.image.rawFile.name,
      };
      save(finalData);
      return;
    }
    save(data);
  };

  return (
    <SimpleForm
      redirect="list"
      record={record}
      toolbar={
        <Toolbar alwaysEnableSaveButton>
          <DeleteButton />
          <SaveButton />
        </Toolbar>
      }
      {...rest}
      save={handleSubmit}
    >
      {!!record.id ? (
        <ImageField source="url" label="Gift image" />
      ) : (
        <ImageInput source="image" label="Gift image">
          <ImageField source="url" label="Gift image" />
        </ImageInput>
      )}

      <SelectInput
        label="Type"
        source="type"
        choices={GiftType}
        validate={[required()]}
      />
      <NumberInput source="credits" />
      <NumberInput
        source="sort_order"
        min={0}
        max={1000}
        validate={[
          required(),
          minValue(0, 'Value must be greater than 1'),
          maxValue(1000, 'Value must be less than 1000'),
        ]}
      />
      <DateTimeInput source="available_from" />
      <DateTimeInput source="available_to" />
    </SimpleForm>
  );
};

export default GiftForm;
