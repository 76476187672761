import { FC } from 'react';
import {
  Create,
  useRedirect,
  useNotify,
  ResourceComponentProps,
} from 'react-admin';
import GiftForm from './GiftForm';

const GiftCreate: FC<ResourceComponentProps> = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify('Gift created');
    redirect('list', props.basePath);
  };

  return (
    <Create title="Create Gift" onSuccess={onSuccess} {...props}>
      <GiftForm />
    </Create>
  );
};

export default GiftCreate;
