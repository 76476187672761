import { FC, memo } from 'react';

import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import 'react-medium-image-zoom/dist/styles.css';

const FULL_PAGINATION_LENGTH = 5;

const useStyles = makeStyles({
  buttonsList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '5px',
    padding: '20px 0',
  },
  button: {
    padding: '8px 12px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'transparent',

    '&:hover': {
      backgroundColor: '#eee',
      cursor: 'pointer',
    },
  },
});

interface Props {
  pages: number[];
  activePage: number;
  onChangeActivePage: (page: number) => void;
}

const CustomPagination: FC<Props> = memo(
  ({ pages, activePage, onChangeActivePage }) => {
    const classes = useStyles();

    const handlePageChange = (event: any) => {
      const nextTargetPage = event.target.innerText;

      if (activePage === Number(nextTargetPage)) {
        return;
      }

      onChangeActivePage(Number(nextTargetPage));
    };

    const handlePrevPage = () => {
      onChangeActivePage(activePage - 1);
    };

    const handleNextPage = () => {
      onChangeActivePage(activePage + 1);
    };

    return (
      <div className={classes.buttonsList}>
        <IconButton
          aria-label="back"
          disabled={activePage === 1}
          onClick={handlePrevPage}
        >
          <ArrowBackIosIcon fontSize="inherit" />
        </IconButton>
        {pages.length <= FULL_PAGINATION_LENGTH &&
          pages.map((item) => (
            <button
              key={item}
              type="button"
              className={classes.button}
              style={{
                color: `${
                  item === activePage
                    ? 'rgb(59, 130, 246)'
                    : 'rgba(0, 0, 0, 0.87)'
                }`,
              }}
              onClick={handlePageChange}
            >
              {item}
            </button>
          ))}

        {pages.length > FULL_PAGINATION_LENGTH && (
          <>
            <button
              type="button"
              className={classes.button}
              style={{
                color: `${
                  activePage === pages[0]
                    ? 'rgb(59, 130, 246)'
                    : 'rgba(0, 0, 0, 0.87)'
                }`,
              }}
              onClick={handlePageChange}
            >
              {pages[0]}
            </button>
            {(activePage === pages[0] || activePage === pages[1]) && (
              <>
                <button
                  type="button"
                  className={classes.button}
                  style={{
                    color: `${
                      activePage === pages[1]
                        ? 'rgb(59, 130, 246)'
                        : 'rgba(0, 0, 0, 0.87)'
                    }`,
                  }}
                  onClick={handlePageChange}
                >
                  {pages[1]}
                </button>
                <button
                  type="button"
                  className={classes.button}
                  style={{
                    color: `${
                      activePage === pages[2]
                        ? 'rgb(59, 130, 246)'
                        : 'rgba(0, 0, 0, 0.87)'
                    }`,
                  }}
                  onClick={handlePageChange}
                >
                  {pages[2]}
                </button>
                ...
              </>
            )}

            {activePage !== pages[0] &&
              activePage !== pages[1] &&
              activePage !== pages.length &&
              activePage !== pages.length - 1 && (
                <>
                  {activePage !== pages[2] && '...'}
                  <button
                    type="button"
                    className={classes.button}
                    style={{
                      color: 'rgba(0, 0, 0, 0.87)',
                    }}
                    onClick={handlePageChange}
                  >
                    {activePage - 1}
                  </button>
                  <button
                    type="button"
                    className={classes.button}
                    style={{
                      color: 'rgb(59, 130, 246)',
                    }}
                    onClick={handlePageChange}
                  >
                    {activePage}
                  </button>
                  <button
                    type="button"
                    className={classes.button}
                    style={{
                      color: 'rgba(0, 0, 0, 0.87)',
                    }}
                    onClick={handlePageChange}
                  >
                    {activePage + 1}
                  </button>
                  {activePage !== pages.length - 2 && '...'}
                </>
              )}

            {(activePage === pages.length - 1 ||
              activePage === pages.length) && (
              <>
                ...
                <button
                  type="button"
                  className={classes.button}
                  style={{
                    color: `${
                      activePage === pages.length - 2
                        ? 'rgb(59, 130, 246)'
                        : 'rgba(0, 0, 0, 0.87)'
                    }`,
                  }}
                  onClick={handlePageChange}
                >
                  {pages.length - 2}
                </button>
                <button
                  type="button"
                  className={classes.button}
                  style={{
                    color: `${
                      activePage === pages.length - 1
                        ? 'rgb(59, 130, 246)'
                        : 'rgba(0, 0, 0, 0.87)'
                    }`,
                  }}
                  onClick={handlePageChange}
                >
                  {pages.length - 1}
                </button>
              </>
            )}

            <button
              type="button"
              className={classes.button}
              style={{
                color: `${
                  activePage === pages.length
                    ? 'rgb(59, 130, 246)'
                    : 'rgba(0, 0, 0, 0.87)'
                }`,
              }}
              onClick={handlePageChange}
            >
              {pages.length}
            </button>
          </>
        )}

        <IconButton
          aria-label="next"
          disabled={activePage === pages.length}
          onClick={handleNextPage}
        >
          <ArrowForwardIosIcon fontSize="inherit" />
        </IconButton>
      </div>
    );
  },
);

export default CustomPagination;
