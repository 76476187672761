import {
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  NumberField,
  ResourceComponentProps,
  SelectField,
  SelectInput,
  TextField,
} from 'react-admin';
import { GiftType } from './field-choices';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  imageCellItem: {
    maxWidth: '50px',
    margin: 0,
    objectFit: 'contain',
  },
}));

const GiftFilter = (props: any) => (
  <Filter {...props}>
    <SelectInput label={'Type'} source="type" choices={GiftType} alwaysOn />
  </Filter>
);

const GiftList = (props: ResourceComponentProps) => {
  const classes = useStyles();
  return (
    <List perPage={30} filters={<GiftFilter />} {...props}>
      <Datagrid optimized rowClick="edit">
        <TextField source="id" label="ID" sortable={false} />
        <SelectField source="type" choices={GiftType} sortable={false} />
        <ImageField
          source="url"
          sortable={false}
          classes={{
            image: classes.imageCellItem,
          }}
        />
        <NumberField source="credits" sortable={false} />
        <NumberField source="sort_order" sortable={false} />
        <DateField source="available_from" showTime={true} sortable={false} />
        <DateField source="available_to" showTime={true} sortable={false} />
      </Datagrid>
    </List>
  );
};

export default GiftList;
