import { FC, useEffect, useState } from 'react';
import { Edit, TabbedForm, FormTab } from 'react-admin';
import { useLocation } from 'react-router';

import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { httpClient } from 'httpClient';

import { DocumentSection } from 'component/trustedUser/DocumentSection';
import { OtherDetailsSection } from 'component/trustedUser/OtherDetailsSection';
import { ShortInfoBar } from 'component/trustedUser/ShortInfoBar';
import MediaList from './submodules/MediaList';
import CustomToolbar from './submodules/CustomToolbar';

import {
  TrustedUser,
  TrustedUserMedia,
} from 'types/trustedUser/trustedUserModeration';
import { DEFAULT_MODERATION_RESPONSE } from './constants';

interface UserProps {
  record?: TrustedUser;
}

const useStyles = makeStyles((theme) => ({
  trustedUserBox: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '90vh',
    maxWidth: '1400px',
    backgroundColor: '#fff',
  },
  tabbedForm: {
    width: '78%',
    maxWidth: '1000px',
    height: '100%',
  },
  tab: {
    minWidth: '16.66%',
    width: '16.66%',
    pointerEvents: 'none',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    margin: '150px 0',
  },
}));

const UserTitle: FC<UserProps> = ({ record }) =>
  record ? <span>Trusted user #{record.id}</span> : null;

const EditForm: FC<UserProps> = ({ record }) => {
  const { pathname } = useLocation();

  const [moderationStatuses, setModerationStatuses] = useState(
    DEFAULT_MODERATION_RESPONSE,
  );
  const [publicPhotos, setPublicPhotos] = useState<TrustedUserMedia[]>([]);
  const [privatePhotos, setPrivatePhotos] = useState<TrustedUserMedia[]>([]);
  const [privateLibrary, setPrivateLibrary] = useState<TrustedUserMedia[]>([]);
  const [videos, setVideos] = useState<TrustedUserMedia[]>([]);
  const [userCount, setUserCount] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    if (!record) return;

    setPublicPhotos(record.public_photos);
    setPrivatePhotos(record.private_photos);
    setPrivateLibrary(record.private_library);
    setVideos(record.videos);

    const path = pathname.includes('re-moderation')
      ? 're-moderation'
      : 'moderation';

    httpClient.get(`/trusted-user/${path}`).then((response) => {
      setUserCount(response.data.meta.total);
    });
  }, [record]);

  if (!record)
    return (
      <div className={classes.loader}>
        <CircularProgress color="secondary" size="30px" />
      </div>
    );

  return (
    <div className={classes.trustedUserBox}>
      <ShortInfoBar record={record} userCount={userCount} />
      <Card className={classes.tabbedForm}>
        <TabbedForm
          toolbar={
            <CustomToolbar
              record={record}
              moderationStatuses={moderationStatuses}
              setModerationStatuses={setModerationStatuses}
              media={{
                public_photos: publicPhotos,
                private_photos: privatePhotos,
                private_library: privateLibrary,
                videos: videos,
              }}
            />
          }
          submitOnEnter={false}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
          }}
        >
          <FormTab
            label="Documents"
            className={classes.tab}
            style={{
              color: moderationStatuses.document_decline_reason
                ? '#d32f2f'
                : 'inherit',
              fontWeight: moderationStatuses.document_decline_reason
                ? 'bold'
                : 'inherit',
            }}
          >
            <DocumentSection record={record} />
          </FormTab>

          <FormTab
            label="Other Details"
            className={classes.tab}
            style={{
              color: moderationStatuses.other_detail_decline_reason
                ? '#d32f2f'
                : 'inherit',
              fontWeight: moderationStatuses.other_detail_decline_reason
                ? 'bold'
                : 'inherit',
            }}
          >
            <OtherDetailsSection record={record} />
          </FormTab>

          <FormTab label="Public Photos" className={classes.tab}>
            <MediaList
              media={publicPhotos}
              setMedia={setPublicPhotos}
              tabNumber={2}
            />
          </FormTab>

          <FormTab label="Private Photos" className={classes.tab}>
            <MediaList
              media={privatePhotos}
              setMedia={setPrivatePhotos}
              tabNumber={3}
            />
          </FormTab>

          <FormTab label="Private library" className={classes.tab}>
            <MediaList
              media={privateLibrary}
              setMedia={setPrivateLibrary}
              tabNumber={4}
            />
          </FormTab>

          <FormTab label="Videos" className={classes.tab}>
            <MediaList media={videos} setMedia={setVideos} tabNumber={5} />
          </FormTab>
        </TabbedForm>
      </Card>
    </div>
  );
};

const TrustedUserModeration = (props: any) => {
  return (
    <Edit {...props} title={<UserTitle />} component="div">
      <EditForm />
    </Edit>
  );
};

export default TrustedUserModeration;
